import React from 'react'
import QuizCategories from './QuizCategories'

const StartGame = () => {
	return (
		<section id='start' className='text-white text-center mb-5 container'>
			<h1 className='display-3 font-weight-bold'>Історія України</h1>
			<p className='lead mb-5'>
				Що там по готовності до НМТ 2024? Перевірмо!
			</p>
			<QuizCategories />
		</section>
	)
}

export default StartGame
