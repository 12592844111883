import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import categories from '../modules/categories'

class QuizCategories extends Component {
	state = {
		name: '',
		id: '',
		startQuiz: false
	}

	defCategory = categories[0];

	handleOnSubmit = e => {
		e.preventDefault()
		console.log();
		if (this.state.id) {
			this.setState({ startQuiz: true })
		} else {
			this.setState({ id: this.defCategory.id, name: this.defCategory.name, startQuiz: true })
		}
	}

	handleChange = e => {
		const { value, options, selectedIndex } = e.target
		this.setState({
			name: options[selectedIndex].innerHTML,
			id: value
		})
	}

	render() {
		const redirectObj = {
			pathname: '/quiz',
			state: { id: this.state.id, category: this.state.name }
		}

		const options = categories.map((category, i) => (
			<option value={category.id} key={category.id}>
				{category.name}
			</option>
		));

		return this.state.startQuiz ? (
			<Redirect to={redirectObj} />
		) : (
			<form className='input-group' onSubmit={this.handleOnSubmit}>
				<select
					className='custom-select'
					aria-label='Вибір категорії'
					onChange={this.handleChange}
					value={this.defCategory.id}
					>
					<option>Вибір категорії...</option>
					{options}
				</select>
				<div className='input-group-append'>
					<button className='btn btn-primary' type='submit'>
						Почати
					</button>
				</div>
			</form>
		)
	}
}

export default QuizCategories
