const categories = [
	// { id: 27, name: 'Animals' },
	// { id: 10, name: 'Books' },
	// { id: 11, name: 'Film' },
	// { id: 9, name: 'General knowledge' },
	// { id: 22, name: 'Geography' },
	// { id: 23, name: 'History' },
	// { id: 12, name: 'Music' },
	// { id: 17, name: 'Science & nature' },
	// { id: 21, name: 'Sports' },
	// { id: 15, name: 'Video Games' }
	{ id: 100, name: 'Історія України' }

]

export default categories
